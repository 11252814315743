<template>
  <div class="custom-page">
    <i class="el-icon-arrow-left" :class="{'disabled':input==1}" @click="prev"></i>
    <div class="custom-page__center">
      <input class="custom-page__input" :disabled="pageSize==1" type="number" v-model="input" @blur="change" @keyup.13="change"/>
      <span v-if="total"><span class="xiegang">/</span>{{pageSize}}</span>
    </div>
    <i class="el-icon-arrow-right" :class="{'disabled':input == pageSize }" @click="next"></i>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 20
    },
  },
  data() {
    return {
      input: 1,
      ischange:false
    }
  },
  watch:{
    input(newVal,oldVal){
      this.ischange=true;
    }
  },
  computed:{
    pageSize(){
      if (this.total) {
        const page = Math.floor(this.total / this.size);
        return (this.total % this.size) ==0?page:page+1;
      }else{
        return 1
      }
    }
  },
  methods: {
    prev(){
      if(this.input > 1 && this.total > 0 && this.pageSize !=1){
        this.input--
        this.$emit('change', this.input);
      }
    },
    next(){
      if (this.input < this.pageSize && this.total > 0) {
        this.input++
       this.$emit('change', this.input);
      }
    },
    change(e) {
      if(!this.ischange)return
      let val = e.target.value;
      if (val<1) {
        this.input=1;
      }
      if (val > this.pageSize) {
        this.input=this.pageSize;
      }
      this.$emit('change', this.input);
      this.ischange=false;
    },
  }
}
</script>

<style lang="less" scoped>
.custom-page {
  display: flex;
  align-items: center;

  &__center {
    display: flex;
    align-items: center;
    margin: 0 7px;
    font-size: 14px;
    .xiegang {
      margin: 0 16px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &__input {
    width: 48px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    font-size: 14px;
    color: #262626;
    border-radius: 4px;
    padding: 3px 8px;
    text-align: center;
    transition: all 300ms ease;
    &[type="number"] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      -moz-appearance: textfield;
    }
    &:focus{
      border: 1px solid #ce3b59;
    }

  }



  .el-icon-arrow {

    &-left,
    &-right {
      padding: 3px 5px;
      cursor: pointer;
      &:hover{
        color: #ce3b59;
        font-weight: 600;
      }
    }
    &-left.disabled,&-right.disabled{
      cursor:not-allowed;
    }
  }
  &{
    -moz-user-select:none;/*火狐*/
  -webkit-user-select:none;/*webkit浏览器*/
  -ms-user-select:none;/*IE10*/
  -khtml-user-select:none;/*早期浏览器*/
  user-select:none;
  }
}
</style>