<template>
  <fd-custom-dialog
      :visible.sync="show"
      :title="title"
      :modal="false"
      custom-class="apply-dialog"
      width="900px"
      :before-close="handleClose"
    >
      <div class="apply-dialog__content">
        <div class="head">
          <p class="fontsize_14 color_26 lineheight_28">
            订单号：<span v-for="(orderNo, ind) in form.order_nos" :key="orderNo">{{orderNo}}</span>
          </p>
          <p class="fontsize_14 color_26 lineheight_28">
            开票金额：<strong class="fontsize_16">{{form.total_price}}</strong>元
          </p>
        </div>
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="130px"
          label-position="left"
          class="margin_top16"
        >
          <el-form-item label="公司名称：" prop="company_name">
            <el-input v-model="form.company_name"></el-input>
          </el-form-item>
          <el-form-item label="公司税号：" prop="tax_number">
            <el-input v-model="form.tax_number"></el-input>
          </el-form-item>
          <el-form-item label="公司注册电话：" prop="reg_number">
            <el-input v-model="form.reg_number"></el-input>
          </el-form-item>
          <el-form-item label="开户银行：" prop="bank_name">
            <el-input v-model="form.bank_name"></el-input>
          </el-form-item>
          <el-form-item label="公司银行账号：" prop="bank_account">
            <el-input v-model="form.bank_account"></el-input>
          </el-form-item>
          <el-form-item label="发票接收邮箱：" prop="email">
            <el-input
              v-model="form.email"
              placeholder="请填写正确的邮箱，用于接收开好的发票。"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2, maxRows: 2 }"
              v-model="form.remark"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="flex flex_jcbetween flex_acenter">
        <div class="fontsize_12 color_8C">
          <p
            class="
              flex flex_acenter
              fontsize_14
              color_26
              lineheight_20
              margin_bot3
            "
          >
            <i class="icon-upgrade-remind fontsize_12 margin_right4"></i>
            发票须知
          </p>
          <p class="flex lineheight_25">
            开票时间：无特殊或不可抗力情况下，我司将在<span class="color_FF0031"
              >7个工作日内</span
            >为您开票，并发送到您填写的邮箱。
          </p>
          <p class="flex lineheight_25">
            开票类型：我司开票类型为<span class="color_FF0031"
              >增值税电子普通发票</span
            >。如有特殊开票要求，可联系在线客服。
          </p>
        </div>
        <el-button class="custom__button" @click="submit('form')"
          >申请开票</el-button
        >
      </div>
    </fd-custom-dialog>
</template>

<script>
export default {
  props: {
    title:{
      type:String,
      default:''
    },
    form:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },

  },
  data() {
    return {
      loading: false,
      show: false,
      rules: {
        company_name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        tax_number: [{ required: true, message: "请输入公司税号", trigger: "blur" }],
        reg_number: [
          { required: true, message: "请输入公司注册电话", trigger: "blur" },
        ],
        bank_name: [{ required: true, message: "请输入开户银行", trigger: "blur" }],
        bank_account: [
          { required: true, message: "请输入公司银行账号", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入发票接收邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  async created(){
 
  },
  watch: {
    visible(val) {
      if (val) {
        this.show = true;
      } else {
        this.show = false;
      }
    }
  },
  computed: {

  },
  methods: {
    submit(formName) {
       this.$refs[formName].validate((valid) => {
          if (valid) {
            
            this.$emit('submit',this.form);
            this.$emit('update:visible',false);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
     handleClose(done) {
      this.$emit('update:visible', false);
      this.$emit('close');
    },
  },
  mounted() {
    
  },
  destroyed() {
    this.$emit('update:visible', false);
  }
};
</script>
<style lang="less" scoped>
@E74362: #e74362;
@ce3b59: #ce3b59;
@8C8C8C: #8c8c8c;
@D9D9D9: #d9d9d9;
@595959: #595959;
@FFFFFF: #ffffff;

/deep/.apply-dialog {
  box-shadow: none;

  .el-dialog {
    &__header {
      padding: 16px;
      border-bottom: 1px solid #ededed;
    }

    &__title {
      font-size: 16px;
      color: #262626;
    }

    &__body {
      padding: 0;
    }

    &__footer {
      padding: 8px 32px;
      border-top: 1px solid #f0f0f0;
    }
  }

  .el-form-item {
    margin-bottom: 16px;

    &__content {
      line-height: 32px;
    }

    &__label {
      color: #262626;
      line-height: 32px;
    }

    &__error {
      padding-top: 1px;
    }

    .el-input {
      height: 32px;
      line-height: 32px;
    }
    .el-input__inner {
      border-radius: 2px;
    }
    .el-textarea__inner {
      border-radius: 2px;
    }
  }
}

.apply-dialog {
  &__content {
    padding: 0 32px;

    .head {
      padding: 9px 16px;
      background: #f8eff1;
      border-radius: 4px;
      margin-top: 16px;
    }
  }
}
.custom__button {
  min-width: 88px;
  min-height: 30px;
  line-height: 20px;
  background: @E74362;
  border: 1px solid @E74362;
  border-radius: 4px;
  font-size: 14px;
  color: @FFFFFF;
  padding: 5px 16px;

  &.other {
    min-width: 80px;
    min-height: 32px;
    color: @E74362;
    background: @FFFFFF;
    border: 1px solid @E74362;

    &:hover {
      color: @FFFFFF;
      background: @E74362;
      border: 1px solid @E74362;
    }
  }

  &.dark {
    color: @FFFFFF;
    background: @E74362;
    border: 1px solid @E74362;

    &:hover {
      color: @FFFFFF;
      background: @ce3b59;
      border: 1px solid @ce3b59;
    }
  }

  &.gray {
    color: @8C8C8C;
    background: @FFFFFF;
    border: 1px solid @D9D9D9;

    &:hover {
      color: @595959;
      background: @FFFFFF;
      border: 1px solid @D9D9D9;
    }
  }
  &.darkgray {
    min-width: 80px;
    min-height: 32px;
    color: #bfbfbf;
    background: #f5f5f5;
    border: 1px solid @D9D9D9;

    &:hover {
      color: #bfbfbf;
      background: #f5f5f5;
      border: 1px solid @D9D9D9;
    }
  }

  &:hover {
    background: @ce3b59;
    border: 1px solid @ce3b59;
  }

  &.full {
    width: 100%;
  }
}
</style>