<template>
  <div class="personal-profile-container">
    <div class="personal-profile-body">
      <header
        class="personal-profile-header flex flex_jcbetween flex_acenter bgc_fff"
      >
        <div>
          <p class="fontsize_16 color_8C lineheight_22">
            当前版本：<span class="color_222">{{version.name}}</span>
          </p>
          <p class="fontsize_14 color_8C lineheight_20 margin_top16">
            <span class="">课程数量：{{version.use_course_num}}/{{version.course_num}}</span>
            <span class="margin_left24">空间容量：{{version.use_zone}}/{{version.zone}}{{version.zone_type}}</span>
            <span class="margin_left24">到期时间：{{version.valid_time}}</span>
          </p>
        </div>
        <el-button class="custom__button other" @click="openUpgradeDialog"
          >升级</el-button
        >
      </header>
      <div class="personal-profile-content">
        <div class="flex flex_acenter flex_jcbetween title">
          <span class="fontsize_16 color_26 lineheight_22">订单记录</span>
          <div class="fontsize_14 color_E74362 margin_top13">
            <span class="hoverColor_B5102F pointer" @click="openBillDialog"
              >申请发票</span
            >
            <span
              class="hoverColor_B5102F margin_left24 pointer"
              @click="toHistory"
              >发票历史</span
            >
          </div>
        </div>
        <div  v-if="total >0" class="custom__table_outer">
          <div class="custom__table">
            <div
              class="custom__table-item"
              v-for="item in lists"
              :key="item.id"
            >
              <div class="custom__table-item__thead">
                <div class="custom__table-item__th">
                  <span>订单编号：{{item.childs[0].order_no}}</span>
                  <span>下单时间：{{item.created_at || '-'}}</span>
                  <span v-if="item.status == 1">支付时间：{{item.pay_time}}</span>
                </div>
                <div class="custom__table-item__th" v-if="item.status != 1">
                  <span
                    >请您在<span class="color_FF4D4F">{{item.cancel_time}}</span
                    >前完成支付，否则订单将自动取消。</span
                  >
                </div>
              </div>
              <div class="custom__table-item__tbody">
                <div class="custom__table-item__tb" v-for="(citem,index) in item.childs" :key="index">
                  <p class="color_59">{{citem.good_name}}：<span class="fontweight_500 color_222">{{citem.good_title}}</span></p>
                  <p>金额：<span>￥{{citem.payment}}</span></p>
                  <p>
                    <span class="color_59" v-if="citem.status == 1">已支付（{{item.pay_way}}）</span>
                    <span class="color_FF7B00" v-else>待支付</span>
                  </p>
                  <p v-if="citem.status == 1">
                    <span class="fontsize_14 color_59"
                      >到期日：{{citem.end_time}}</span
                    >
                  </p>
                  <p v-else>
                    <el-button class="custom__button " @click="toPay(citem)">立即支付</el-button>
                    <el-button class="custom__button gray" @click="cancelOrder(citem)">取消订单</el-button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="padding_top16 padding_bot24 padding_0_32 flex flex_end">
            <customPage @change="handlePageChange" :total="total" :size="limit"></customPage>
          </div>
        </div>
        <div class="custom__nodata" v-if="total ==0">
          <div
            class="
              fontsize_14
              lineheight_20
              flex
              felx_acenter
              flex_center
              flexcolumn
            "
          >
            <img src="@/assets/nodata/pay_nodata.svg" height="156px" />
            <span class="margin_top16 color_8C flex felx_acenter flex_center"
              >您暂无订单记录</span
            >
            <span
              class="
                margin_top13
                color_E74362
                flex
                felx_acenter
                flex_center
                pointer
                hoverColor_B5102F
              "
              @click="openUpgradeDialog"
              >去升级</span
            >
          </div>
        </div>
      </div>
    </div>
    <fd-upgrade-dialog
      :show.sync="upgradeDialogVisible"
      @close="closeUpgradeDialog"
      :changePay="changePay"
      :currentBox="currentBox"
      :before-finish-pay="finishPay"
    ></fd-upgrade-dialog>
    <fd-custom-dialog
      :visible.sync="billDialogVisible"
      title="选择开票订单"
      :before-close="closeBillDialog"
      custom-class="bill-dialog"
      width="900px"
    >
      <div v-if="btotal>0">
        <div class="bill-dialog__table">
          <div
            class="bill-dialog__table-item"
            v-for="item in billLists"
            :key="item.id"
          >
            <div class="bill-dialog__table-item__thead">
              <div class="bill-dialog__table-item__th">
                <el-checkbox
                  :value="item.check"
                  class="item-checkbox"
                  @change="selectBillItem(item)"
                ></el-checkbox>
                <span>订单编号：{{item.childs[0].order_no}}</span>
                <span>下单时间：{{item.created_at}}</span>
                <span>支付时间：{{item.pay_time}}</span>
              </div>
            </div>
            <div class="bill-dialog__table-item__tbody">
              <div class="bill-dialog__table-item__tb fontsize_14 color_59" v-for="(citem,index) in item.childs" :key="index">
                <p>{{citem.good_name}}：<strong class="color_26">{{citem.good_title}}</strong></p>
                <p>金额：<strong class="color_26">￥{{citem.payment}}</strong></p>
                <p>
                  <span>已支付（{{item.pay_way}}）</span>
                  <!-- <span class="color_FF7B00">待支付</span> -->
                </p>
                <p>
                  <span>到期日：{{citem.end_time}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex_end padding_16_32">
          <customPage @change="changeBillPage" :total="btotal" :size="blimit"></customPage>
        </div>
      </div>
      <div class="custom__nodata" style="min-height: 399px" v-if="btotal==0">
        <div
          class="
            fontsize_14
            lineheight_20
            flex
            felx_acenter
            flex_center
            flexcolumn
          "
        >
          <img src="@/assets/nodata/bill_nodata.svg" height="156px" />
          <span class="margin_top16 color_8C flex felx_acenter flex_center"
            >暂无可开票的购买订单记录</span
          >
          <span
            class="
              margin_top13
              color_E74362
              flex
              felx_acenter
              flex_center
              pointer
              hoverColor_B5102F
            "
            @click="toHistory"
            >查看发票历史</span
          >
        </div>
      </div>
      <div slot="footer" class="flex flex_jcbetween flex_acenter">
        <div class="fontsize_14 color_26">
          开票金额：<strong>{{totalPrice}}</strong> 元
        </div>
        <el-button
          class="custom__button "
          :class="{'darkgray':btotal==0}"
          :disabled="false"
          @click="openApplyDialog"
          >申请开票</el-button
        >
      </div>
    </fd-custom-dialog>
    <!-- 申请开票 -->
    <invoice-dialog
      :visible.sync="applyDialogVisible"
      title="申请开票"
      :form="form"
      @submit="submitApply"
    ></invoice-dialog>
  </div>
</template>

<script>
import { getToken } from "@/libs/utils/auth";
import customButton from "@/renderer/components/common/button.vue";
import customPage from "@/renderer/components/common/customPage.vue";
import invoiceDialog from "@/renderer/components/common/invoiceDialog.vue";
import { mapActions } from "vuex";
import { getOrderList, cancelUpgrade, invoiceApply } from "@/libs/api/upgrade";
export default {
  components: { customButton, customPage,invoiceDialog },
  data() {
    return {
      token: null,
      page: 1,
      limit:3,
      total:-1,
      currentBox:2,
      upgradeDialogVisible: false,
      applyDialogVisible: false,
      billDialogVisible: false,
      billLists: [{ check: false }, { check: true }],
      version:{},
      lists:[],
      form: {
        order_no: [],
        totalPrice:0.00,
        company_name: "",
        tax_number: "",
        reg_number: "",
        bank_name: "",
        bank_account: "",
        remark: "",
        email: "",
      },
      bpage:1,
      blimit:2,
      btotal:-1,
      totalPrice:0.00,
      selectObj:{},
    };
  },
  computed: {},
  async created() {
    const token = await getToken();
    this.token = token;

    this.getInfo();
    this.getLists('upgrade');
  },
  methods: {
    ...mapActions(["GetUserDetail"]),
    getInfo() {
      this.GetUserDetail()
        .then((res) => {
          if (res.errorcode == 0) {
            this.version = res.data.version;
          }
        })
        .catch();
    },
    getLists(type){
      let data = {page:this.page,limit:this.limit,type:'upgrade'};
      if (type == 'invoice') {
        data = {page:this.bpage,limit:this.blimit,type:'invoice'};
      }
      getOrderList(data)
        .then(res=>{
          if (res.errorcode==0) {
            if (type =='upgrade') {
              this.lists=res.data;
              this.total=res.total;
            }
            if (type == 'invoice') {
              res.data.map(item=>{
                item.check=this.selectObj[item.id]?true:false
              })
              this.billLists=res.data;
              this.btotal=res.total;
              this.billDialogVisible = true;
            }
          
          }
        })
        .catch(err=>{})
    },
    handlePageChange(page) {
      this.page = page;
      this.getLists('upgrade');
    },
    cancelOrder(item){
      this.$confirm(`确定取消该订单（${item.order_no}）吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass:'cancelButtonClass',
        confirmButtonClass:'confirmButtonClass',
        type: 'warning'
      }).then(() => {
        cancelUpgrade({order_id:item.id})
          .then(res=>{
            if (res.errorcode==0) { 
              this.$message({type: 'success',message: '取消成功!'});
              this.getLists('upgrade');
            }
          })
        
      })
      
    },
    toPay(item){
      this.currentBox=2;
      this.upgradeDialogVisible=true;
      this.sid=item.sid;
      this.order_id=item.order_id;
    },
    changePay(done){
      if (this.upgradeDialogVisible) {
        done({sid:this.sid,order_id:this.order_id})
      }
    },
    finishPay(){
      this.getLists('upgrade');
    },
    openUpgradeDialog() {
      this.currentBox=1;
      this.upgradeDialogVisible = true;
    },
    closeUpgradeDialog() {},
    openBillDialog() {
      this.getLists('invoice')
    },
    changeBillPage(page){
      this.bpage=page;
      this.getLists('invoice')
    },
    selectBillItem(item) {
      item.check = !item.check;
      if (item.check) {
        this.selectObj[item.id]=item;
      }else{
        delete this.selectObj[item.id]
      }
      this.countTotalPrice()
    },
    // 计算开票金额
    countTotalPrice(){
      let totalPrice =0;
      let order_no_arr = [];
      Object.values(this.selectObj).forEach((item,index)=>{
        totalPrice+=Number(item.total_price);
        order_no_arr.push(item.childs[0].order_no)
      })
      this.totalPrice =Number(totalPrice.toFixed(2));
      this.form.order_no=order_no_arr;
      this.form.totalPrice=totalPrice;
      this.form.order_nos=order_no_arr;
      this.form.total_price=totalPrice;
    },
    openApplyDialog() {
      if (this.btotal ==0 || Object.keys(this.selectObj).length ==0) {
        this.$message('请选择开票订单')
        return false
      }
      this.applyDialogVisible = true;
    },
    // 提交申请开票
    submitApply(obj){
      invoiceApply(obj)
        .then(res=>{
          if (res.errorcode ==0) {
            this.$message.success('开票成功')
            this.applyDialogVisible = false;
            this.closeBillDialog()
          }
        })
    },
    closeBillDialog(){
      this.billDialogVisible=false;
      this.selectObj={};
      this.totalPrice=0.00;
      this.bpage=1;
    },
    toHistory() {
      this.$router.push("/history");
    },
  },
};
</script>

<style lang="less" scoped>
@E74362: #e74362;
@ce3b59: #ce3b59;
@8C8C8C: #8c8c8c;
@D9D9D9: #d9d9d9;
@595959: #595959;
@FFFFFF: #ffffff;

.personal-profile-container {
  box-sizing: border-box;
  padding: 16px 0;
  height: 100%;
}

.personal-profile-body {
  // box-sizing: border-box;
  margin: 0 auto;
  border-radius: 4px;
  padding: 0 24px;
  width: 62.5%;
  height: 100%;
  max-width: 900px;
  max-height: 800px;
}

.personal-profile-header {
  height: 90px;
  border-radius: 4px;
  padding: 16px 32px;
  box-sizing: border-box;
}

.personal-profile-content {
  height: calc(100% - 122px);
  background-color: @FFFFFF;
  border-radius: 4px;
  margin-top: 16px;

  .title {
    height: 54px;
    padding: 0 32px;
    border-bottom: 1px solid #f0f0f0;
  }
}

.custom__table_outer {
  height: 100%;
}

.custom__table {
  // max-height: 540px;
  height: calc(100% - 130px);
  padding: 0 32px;
  overflow-y: scroll;
  &::-webkit-scrollbar{width:0;height:0}
  &::-webkit-scrollbar-button{width:0;height:0}
  &::-webkit-scrollbar-track{background:0 0}

  &-item {
    border-radius: 4px;
    border: 1px solid #f7e7ea;
    margin-top: 16px;

    &__thead {
      padding: 18px 16px;
      background: #f8eff1;
      border-bottom: 1px solid #f7e7ea;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
    }

    &__th {
      font-size: 12px;
      color: #595959;

      &:first-child > span {
        margin-right: 24px;
        font-size: 14px;
      }
    }

    &__tbody {
    }

    &__tb {
      flex-wrap: wrap;
      // padding: 16px;
      min-height: 100px;
      box-sizing: border-box;
      // display: grid;
      // grid-template-columns: 1fr 1.3fr 1fr 2fr;
      // gap:0 24px ;
      display: flex;

      align-items: center;
      font-size: 14px;

      & > p {
        flex: 1;
        padding: 16px;
        white-space: nowrap;
      }

      & > p:last-child {
        flex: auto;
        text-align: right;
      }
    }
  }
}

.bill-dialog__table {
  max-height: 360px;
  padding: 0 32px;
  overflow-y: scroll;

  &-item {
    border-radius: 4px;
    border: 1px solid #f7e7ea;
    margin-top: 16px;

    /deep/.item-checkbox {
      margin-right: 10px;

      .el-checkbox__inner {
        width: 16px;
        height: 16px;
        background: @FFFFFF;
        border: 1px solid #d4d4d4;
        border-radius: 2px;

        &:hover {
          border: 1px solid @E74362;
        }

        &::after {
          height: 9px;
          left: 5px;
        }
      }

      .is-focus .el-checkbox__inner {
        border: 1px solid @E74362;
      }

      &.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background: @E74362;
        border: 1px solid @E74362;
      }
    }

    &__thead {
      padding: 18px 16px;
      background: #f8eff1;
      border-bottom: 1px solid #f7e7ea;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
    }

    &__th {
      font-size: 12px;
      color: #595959;
      width: 100%;

      & > span {
        margin-right: 24px;
        font-size: 14px;
        flex: 1;
      }
    }

    &__tbody {
    }

    &__tb {
      min-height: 100px;
      box-sizing: border-box;
      display: flex;

      align-items: center;
      font-size: 14px;

      & > p {
        flex: 1;
        padding: 16px;
        white-space: nowrap;
      }
    }
  }
}

.custom__nodata {
  min-height: 578px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @FFFFFF;
}

/deep/.bill-dialog {
  box-shadow: none;

  .el-dialog {
    &__header {
      padding: 16px;
      border-bottom: 1px solid #ededed;
    }

    &__title {
      font-size: 16px;
      color: #262626;
    }

    &__body {
      padding: 0;
    }

    &__footer {
      padding: 20px 32px;
      border-top: 1px solid #f0f0f0;
    }
  }
}




.custom__button {
  min-width: 88px;
  min-height: 30px;
  line-height: 20px;
  background: @E74362;
  border: 1px solid @E74362;
  border-radius: 4px;
  font-size: 14px;
  color: @FFFFFF;
  padding: 5px 16px;

  &.other {
    min-width: 80px;
    min-height: 32px;
    color: @E74362;
    background: @FFFFFF;
    border: 1px solid @E74362;

    &:hover {
      color: @FFFFFF;
      background: @E74362;
      border: 1px solid @E74362;
    }
  }

  &.dark {
    color: @FFFFFF;
    background: @E74362;
    border: 1px solid @E74362;

    &:hover {
      color: @FFFFFF;
      background: @ce3b59;
      border: 1px solid @ce3b59;
    }
  }

  &.gray {
    color: @8C8C8C;
    background: @FFFFFF;
    border: 1px solid @D9D9D9;

    &:hover {
      color: @595959;
      background: @FFFFFF;
      border: 1px solid @D9D9D9;
    }
  }
  &.darkgray {
    min-width: 80px;
    min-height: 32px;
    color: #bfbfbf;
    background: #f5f5f5;
    border: 1px solid @D9D9D9;

    &:hover {
      color: #bfbfbf;
      background: #f5f5f5;
      border: 1px solid @D9D9D9;
    }
  }

  &:hover {
    background: @ce3b59;
    border: 1px solid @ce3b59;
  }

  &.full {
    width: 100%;
  }
}
</style>